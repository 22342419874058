import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {AuthGuard} from './common/auth/guards/auth.guard';
import {DevComponent} from './application/dev/dev.component';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: 'dev', component: DevComponent, pathMatch: 'full', canActivate: [AuthGuard]
            },
            {
                path: '',
                loadChildren: () => import('./application/application.module').then(m => m.ApplicationModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'public',
                loadChildren: () => import('./application/public/public-section.module').then(m => m.PublicSectionModule),
            },
        ], {scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled'}),
    ],
    providers: [
        AuthGuard,
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
