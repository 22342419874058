<div id="content">
    <div class="row">
        <div class="col-sm-12">
            <div class="well">
                <div class="lightest-gray-bg">
                    <div class="widget-body clearfix">
                        <div class="row">
                            <div class="col-xs-12 info-header">
                                DEVELOPMENT MODE
                            </div>
                        </div>
                        <hr style="margin: 15px 10px;">
                        <div class="row">
                            <div class="col-xs-6 info-row-label">
                                User loaded remotely:
                            </div>
                            <div class="col-xs-6">
                                {{userLoadedRemotely && 'TRUE' || 'FALSE'}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
