import * as _ from 'lodash';
import {environment, SYSTEM_NAME} from '../../../environments/environment.dev';
import {BASE_FRONTEND_URL, DEV_HOST, getBaseApiUrl, getBaseFrontendUrl, LOCAL_HOST, PROD_HOST} from '../urls';
import {UkAppsEnum} from '../uk-apps';

export const AUTH_BASE_URL = getBaseApiUrl('AUTH');
const BASE_URL = getBaseApiUrl(SYSTEM_NAME);

export const AUTH_BASE_COOKIE_DOMAIN = getAuthBaseCookieDomain();

// constructs URL for auth redirection
function getAuthRedirectUrl(): string {
    return getBaseFrontendUrl('AUTH');
}

// constructs base domain for cookie storage
function getAuthBaseCookieDomain(): string {
  if (environment.production) {
      return 'tuke.sk';
  } else if (environment.isLocal) {
      return 'localhost';
  }
  return 'tuke.sk';
}

// constructs redirect URL for specified app
function getAppRedirectUrl(systemName: UkAppsEnum): string {
    return `${BASE_FRONTEND_URL}/${_.toLower(systemName)}`;
}

export const AUTH_REDIRECT_URL = getAuthRedirectUrl();

export const APP_URL_EPC = getAppRedirectUrl(UkAppsEnum.EPC);
export const APP_URL_ETD = getAppRedirectUrl(UkAppsEnum.ETD);
export const APP_URL_ISBN = getAppRedirectUrl(UkAppsEnum.ISBN);
export const APP_URL_ROOMBOOK = getAppRedirectUrl(UkAppsEnum.ROOMBOOK);
export const APP_URL_ZP = getAppRedirectUrl(UkAppsEnum.ZP);
export const APP_URL_UIK = getAppRedirectUrl(UkAppsEnum.UIK);
export const APP_URL_HELPDESK = getAppRedirectUrl(UkAppsEnum.HELPDESK);
export const APP_URL_TOOLS = getAppRedirectUrl(UkAppsEnum.TOOLS);
export const APP_URL_LIBADMIN = getAppRedirectUrl(UkAppsEnum.LIBADMIN);
export const APP_URL_ALFRED = getAppRedirectUrl(UkAppsEnum.ALFRED);

/* ### AUTH URLS ### */
const API_LOGIN = '/auth/token';
const API_LOGOUT = '/auth/logout';
const API_GET_EMPLOYMENTS = '/auth/getEmployments';
const API_CHANGE_EMPLOYMENT = '/auth/changeEmployment';

export function getLoginUrl() {
    return `${BASE_URL}${API_LOGIN}`;
}

export function getLogoutUrl() {
    return `${BASE_URL}${API_LOGOUT}`;
}

export function getEmploymentsUrl() {
  return `${BASE_URL}${API_GET_EMPLOYMENTS}`;
}

export function changeEmploymentUrl() {
  return `${BASE_URL}${API_CHANGE_EMPLOYMENT}`;
}

export function getAppConfigurationUrl() {
  return `${BASE_URL}/config/client`;
}
